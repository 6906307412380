<template>
  <el-card id="recycle">
    <header>
      <h1>公司管理</h1>
      <div class="search">
        <div class="right">
          <el-button
            type="primary"
            @click="moreDelete"
            class="delete"
            icon="el-icon-delete"
            size="small"
            >批量删除</el-button
          >
          <el-button
            type="primary"
            @click="moreRestore"
            class="delete"
            icon="el-icon-delete"
            size="small"
            >批量还原</el-button
          >
          <el-button
            type="primary"
            @click="moreDeleteAll"
            class="delete"
            icon="el-icon-delete"
            size="small"
            >清空回收站所有数据</el-button
          >
        </div>
      </div>
    </header>
    <el-divider></el-divider>
    <main>
      <el-table
        :data="tableData"
        border
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handleSelectionChange"
      >
        <!-- 多选按钮 -->
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="activePhone"
          label="手机号码"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="company"
          label="企业名称"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="customerPhone"
          label="姓名"
          align="center"
        ></el-table-column>
        <el-table-column prop="status" label="状态" align="center">
          <template #default="scope">
            <span v-if="scope.row.status == 0">一般客户</span>
            <span v-if="scope.row.status == 1">意向客户</span>
            <span v-if="scope.row.status == 2">无意向客户</span>
            <span v-if="scope.row.status == 3">成交客户</span>
            <span v-if="scope.row.status == 4">黑名单</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="300" align="center">
          <template #default="scope">
            <el-button
              type="danger"
              @click="removeButton(scope.row)"
              size="small"
              >删除</el-button
            >

            <el-button type="primary" @click="update(scope.row)" size="small"
              >还原</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--分页  -->
      <div class="pagination">
        <el-pagination
          background
          layout="total, prev, pager, next,sizes"
          :current-page="pageIndex"
          :page-size="pageSize"
          :page-sizes="[10, 20, 50, 100, 200]"
          :total="pageTotal"
          @size-change="handleSizeChange"
          @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </main>

    <!-- 批量删除弹出框 -->
    <el-dialog title="提示" :visible.sync="allRemoveVisible" width="30%">
      <span>确定要删除所选内容吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allRemoveVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitDeleteList()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 批量还原弹出框 -->
    <el-dialog title="提示" :visible.sync="allRestoreVisible" width="30%">
      <span>确定要还原所选内容吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allRestoreVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitRestoreList()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 清空回收站弹出框 -->
    <el-dialog title="提示" :visible.sync="allRemoveVisibleAll" width="30%">
      <span>确定要清空回收站所有数据吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="allRemoveVisibleAll = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitRestoreListAll()"
            >确 定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 删除弹出框 -->
    <el-dialog title="删除" :visible.sync="delVisible" width="30%">
      <span>确定删除这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="delVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submitDel()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 还原弹出框 -->
    <el-dialog title="还原" :visible.sync="huanyuanVisible" width="30%">
      <span>确定还原这段号码吗？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="huanyuanVisible = false"
            >取 消</el-button
          >
          <el-button type="primary" @click="submithuanyuan()">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();
import {
  phoneList,
  phoneRecycleBin,
  phoneDelete,
  phoneClearRecycleBin,
} from "@/api/phone";
export default {
  data() {
    return {
      huanyuanVisible: false,
      delVisible: false,
      allRemoveVisibleAll: false,
      allRestoreVisible: false,
      allRemoveVisible: false,
      numsArray: [], //选中号码的ID
      tableData: [], //表格内绑定的数据
      pageTotal: 0, //页数
      pageIndex: 1, //当前在第几页
      pageSize: 10, //前端规定每页显示的数量
      page: 1, //第几页
      size: 10, //每页的数量
    };
  },
  created() {
    this.realname = localStorage.getItem("realname");
    this.roleName = localStorage.getItem("roleName");
    this.id = localStorage.getItem("id");
    this.getList();
  },
  methods: {
    // 分页
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    // 展示
    getList() {
      let params = {
        page: this.page,
        size: this.size,
        recycleBin: 0,
        roleId: 0,
      };
      let res = phoneList(params);
      res.then((res) => {
        this.tableData = res.data.data;
        this.pageTotal = res.data.total;
      });
    },
    // 还原按钮
    update(data) {
      let params = {
        listId: [data.id],
        recycleBin: 1,
      };

      this.removeParams = params;
      this.huanyuanVisible = true;
    },
    // 还原确认事件
    submithuanyuan() {
      let res = phoneRecycleBin(this.removeParams);
      res.then((res) => {
        Message.success("还原成功");
        this.tableData.splice(this.removeIndex, 1);
        this.huanyuanVisible = false;
      });
    },
    // 删除按钮
    removeButton(data) {
      let params = {
        listId: [data.id],
        // recycleBin: 0,
        roleId: "",
        departId: "",
      };

      this.removeParams = params;
      this.delVisible = true;
    },
    // 删除确认事件
    submitDel() {
      let res = phoneDelete(this.removeParams);
      res.then((res) => {
        Message.success("删除成功");
        this.tableData.splice(this.removeIndex, 1);
        this.delVisible = false;
        this.getList();
      });
    },
    //   清空回收站弹出框
    moreDeleteAll() {
      this.allRemoveVisibleAll = true;
    },
    //   清空回收站
    submitRestoreListAll() {
      let params = {};
      let res = phoneClearRecycleBin(params);
      res.then((res) => {
        Message.success(res.data.message);
        this.allRemoveVisibleAll = false;
        this.getList();
      });
    },
    // 批量还原
    moreRestore() {
      // console.log(this.tableData);
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.allRestoreVisible = true;
    },
    // 确认批量还原
    submitRestoreList() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法还原");
            return;
          }
        });
      });
      let params = {
        listId: this.numsArray,
        recycleBin: 1,
      };
      let res = phoneRecycleBin(params);
      res.then((res) => {
        Message.success("还原成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.allRestoreVisible = false;
        this.getList();
      });
    },
    // 批量删除
    moreDelete() {
      // console.log(this.tableData);
      if (this.numsArray.length == 0) {
        Message.warning("未选中号码");
        return;
      }
      this.allRemoveVisible = true;
    },
    // 确认批量删除
    submitDeleteList() {
      this.numsArray.map((v, i) => {
        this.tableData.findIndex((item) => {
          if (item.status == 3 || item.status == 4) {
            Message.warning("选中列表中包含成交客户或黑名单，无法删除");
            return;
          }
        });
      });
      let params = {
        listId: this.numsArray,
        roleId: "",
        departId: "",
      };
      let res = phoneDelete(params);
      res.then((res) => {
        Message.success("删除成功");
        this.numsArray.map((v, i) => {
          let index = this.tableData.findIndex((item) => item.id === v);
          this.tableData.splice(index, 1);
        });
        this.allRemoveVisible = false;
        this.getList();
      });
    },
    //选择table表格
    handleSelectionChange(val) {
      this.numsArray = [];
      val.forEach((item) => {
        this.numsArray.push(item.id);
      });
      // console.log(this.numsArray)
    },
  },
};
</script>

<style lang="less" scoped>
#recycle {
  header {
    h1 {
      font-weight: normal;
    }
    .search {
      margin-top: 20px;
      .right {
        margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
  }
  main {
    .pagination {
      text-align: right;
      margin: 20px 0;
    }
  }
}
</style>